"use client"
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import Image from 'next/image';
import {Autoplay, FreeMode} from 'swiper/modules';
import {useRouter} from "next/navigation";

export default function HeroMarqueeWall({brands}: HeroMarqueeWallProps) {
	const router = useRouter()

	const onBrandClick = () => {
		router.push(`/company/references`)
	}

	return (
		<div className="relative max-w-[100vw] w-[343px] md:w-[696px] xl:w-[1080px] py-12">
			<Swiper
				modules={[FreeMode, Autoplay]}
				spaceBetween={0}
				speed={1500 * brands.length}
				slidesPerView={'auto'}
				loop={true}
				allowTouchMove={false}
				autoplay={{delay: 0, disableOnInteraction: false}}
				freeMode={{enabled: true, momentum: false, momentumBounce: false}}
			>
				<SwiperSlide className="!w-auto text-4xl !flex items-center justify-center">
					{brands.map((brand) => (
						<Image
							key={brand}
							src={`/brands/marquee/${brand}.png`}
							loading={"eager"}
							alt={brand}
							width={0}
							height={0}
							sizes={"200px"}
							className={"h-5 md:h-6 xl:h-8 w-auto mx-[30px]"}
						/>
					))}
				</SwiperSlide>
				<SwiperSlide className="!w-auto text-4xl !flex items-center justify-center">
					{brands.map((brand) => (
						<Image
							key={brand}
							src={`/brands/marquee/${brand}.png`}
							loading={"eager"}
							alt={brand}
							width={0}
							height={0}
							sizes={"200px"}
							className={"h-5 md:h-6 xl:h-8 w-auto mx-[30px]"}
						/>
					))}
				</SwiperSlide>
			</Swiper>
			<div className="absolute inset-0 z-20 hover:cursor-pointer" onClick={onBrandClick}></div>
		</div>
	);
}

interface HeroMarqueeWallProps {
	brands: brandName[];
}

export type brandName =
	| "akode"
	| "anadolubank"
	| "misyon"
	| "dgpays"
	| "flogroup"
	| "hepsiburada"
	| "hepsipay"
	| "hepsifinans"
	| "moka"
	| "nurolbank"
	| "oyak_yatırım"
	| "pazarama"
	| "unitedpayment"
	| "moneypay"
	| "papara"
	| "sipay"
	| "papel"
	| "moneytolia"
	| "colendi"
	| "goldenglobal"
	| "hayatfinans"
	| "halkode"
	| "bpn";
