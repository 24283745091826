import HeroContent from "@/components/hero/HeroContent";
import HeroNews from "@/components/hero/HeroNews";
import HeroMarqueeWall, {brandName} from "@/components/hero/HeroMarqueeWall";
import classNames from "classnames";

export default function HeroComponent({imageName, title, subtitle, buttonTitle, description, buttonHref, showNews, brands}: HeroComponentProps){
	const bg = "bg-1280-"+ imageName;
	return (
		<section className={classNames(`w-full h-[100vh] min-h-fit pt-12 md:pt-20 flex flex-col justify-between items-center bg-no-repeat bg-cover`, bg )}>
			{showNews ? <HeroNews title={"Exciting News from Formica AI! Join us at Money20/20 in Amsterdam!"}/> :
			<p/>}
			<HeroContent title={title} subtitle={subtitle} buttonTitle={buttonTitle} description={description} buttonHref={buttonHref}/>
			{brands ? <HeroMarqueeWall brands={brands}/> : <p/>}
		</section>
	)
}

interface HeroComponentProps {
	//ImageName must be defined in the tailwind.config.js
	imageName?: string,
	showNews?: boolean,
	title?: string,
	subtitle?: string,
	description?: string,
	buttonTitle?: string,
	buttonHref?: string,
	brands?: brandName[]
}
