import {GenericContainer} from "@/components/generic/GenericContainer";
import Image from "next/image";
import Icon from "@/components/icon/Icon";
import SocialMediaIconGroup from "@/components/footer/SocialMediaIconGroup";
import {useTranslations} from "next-intl";

export default function FooterComponent() {
	const t = useTranslations('');

	const data = {
		Solutions: [
			{title: t("footer.transactionMonitoring"), href: "/solutions/transaction-monitoring"},
			{title: t("footer.accountOpening"), href: "/solutions/account-opening"},
			{title: t("footer.accountProtection"), href: "/solutions/account-protection"},
			{title: t("footer.scamPrevention"), href: "/solutions/scam-prevention"},
			{title: t("footer.paymentOptimization"), href: "/solutions/payment-optimization"},
			{title: t("footer.aml"), href: "/solutions/aml-kyc"}
		],
		Industries: [
			{title: t("footer.banking"), href: "/industries/banking"},
			{title: t("footer.paymentFacilitator"), href: "/industries/payment-facilitator"},
			{title: t("footer.eCommerce"), href: "/industries/e-commerce"},
			{title: t("footer.travelAndTicketing"), href: "/industries/travel-and-ticketing"},
			{title: t("footer.bnpl"), href: "/industries/bnpl"}
		],
		Company: [
			{title: t("footer.aboutUs"), href: "/company/about-us"},
			{title: t("footer.press"), href: "/company/press"},
			{title: t("footer.references"), href: "/company/references"},
			{title: t("footer.resources")},
			{title: t("footer.blogs"), href: "/blog"},
			{title: t("footer.ebooks"), href: "/e-books"}
		],
		Offices: [
			{title: t("footer.london")},
			{
				title: "20-22, Wenlock \nRoadLondon, N1 7GU \nEngland",
				href: "https://maps.app.goo.gl/8vSL4PWbbnBkEkwt7",
				openInNewTab: true
			},
			{title: t("footer.istanbul")},
			{
				title: "Biruni Teknopark, Kazlıçeşme Mahallesi\n245. Sokak, No:5,\nZeytinburnu, Istanbul",
				href: "https://maps.app.goo.gl/qb3mnYivg5AwkWBi7",
				openInNewTab: true
			}
		]
	};

	return (
		<GenericContainer className={"bg-footer-bg bg-no-repeat bg-100% flex flex-col items-center"}>
			<div className={"items-center flex flex-col"}>
				<div className={"flex flex-row md:justify-between justify-center w-full"}>
					<a href={"/"} className={"w-[122px] xl:hidden flex"}>
						<Image

							src={"/logo/Logo.svg"} alt={"Formica Logo"}
							width={122} height={20}/>
					</a>
					<SocialMediaIconGroup containerClassName={"md:!flex !hidden xl:!hidden"}/>
				</div>
				<Image src={"/FooterLine.svg"} alt={"Footer Line"} width={0} height={1}
				       className={"h-12 w-full max-w-container xl:hidden flex"}/>
			</div>
			<div
				className={"flex flex-col md:flex-row md:justify-between md:w-full max-w-container items-center md:items-start"}>
				{Object.keys(data).map((item: string, index: number) => {
					return (
						<div key={index}
						     className={"flex flex-col min-w-fit gap-1 md:gap-2 xl:gap-3 md:max-w-[2rem] xl:max-w-[3rem] items-center md:items-start"}>
							<p className={"font-bold text-white text-sm"}>{t(`footer.${item.toLowerCase()}`)}</p>
							{data[item].map((subItem: {
								href: string | undefined;
								title: string | undefined;
								openInNewTab: boolean | undefined
							}) => {
								return subItem && subItem.href ? (
									<a key={subItem.title}
									   className={"text-white text-sm font-normal whitespace-pre-wrap text-center md:text-start"}
									   href={subItem.href} target={subItem.openInNewTab ? "_blank" : "_self"} rel="noopener noreferrer">
										{subItem.title}
									</a>
								) : (
									<p key={subItem.title} className={"font-bold text-white text-sm"}>
										{subItem.title}
									</p>
								);
							})}
							<div className={"h-12 justify-center flex md:h-0"}>
								<Image src={"/FooterLine.svg"} alt={"Footer Line"} width={0} height={1}
								       className={"xl:hidden w-full max-w-container"}/>
							</div>
						</div>
					);
				})}
			</div>
			<Image src={"/FooterLine.svg"} alt={"Footer Line"} width={0} height={1}
			       className={"w-full max-w-container hidden md:flex md:h-12"}/>
			<div
				className={"flex flex-col xl:flex-row justify-between gap-6 xl:gap-0 px-7 items-center max-w-container w-full"}>
				<a href={"/"} className={"w-[122px] hidden xl:flex"}>
					<Image

						src={"/logo/Logo.svg"}
						alt={"Formica Logo"} width={122} height={20}/>
				</a>
				<div className={"flex flex-row gap-3"}>
					<a
						className={"min-w-fit flex flex-row align-middle justify-center items-center gap-1 text-white text-2xs font-light"}>
						<Icon icon={"certificate"} width={16} className={"fill-white"}/>
						<p>ISO 27001</p>
					</a>
					<a
						className={"min-w-fit flex flex-row align-middle justify-center items-center gap-1 text-white text-2xs font-light"}>
						<Icon icon={"certificate"} width={16} className={"fill-white"}/>
						<p>GDPR</p>
					</a>
					<a
						className={"min-w-fit flex flex-row align-middle justify-center items-center gap-1 text-white text-2xs font-light"}>
						<Icon icon={"certificate"} width={16} className={"fill-white"}/>
						<p>ISO 9001</p>
					</a>
					<a
						className={"min-w-fit flex flex-row align-middle justify-center items-center gap-1 text-white text-2xs font-light"}>
						<Icon icon={"certificate"} width={16} className={"fill-white"}/>
						<p>ISO 12207</p>
					</a>
				</div>
				<Image src={"/FooterLine.svg"} alt={"Footer Line"} width={0} height={1}
				       className={"h-[1px] w-full max-w-container flex md:hidden"}/>
				<SocialMediaIconGroup/>
			</div>
			<Image src={"/FooterLine.svg"} alt={"Footer Line"} width={0} height={1}
			       className={"h-12 w-full max-w-container flex"}/>
			<div className={"flex flex-col pt-0 md:flex-row justify-center gap-5 items-center"}>
				<a className={"text-white text-sm font-normal"} href={"/privacy-policy"}>
					{t("footer.privacyPolicy")}
				</a>
				<div className={"h-1 w-1 bg-white/30 rounded-full"}/>
				<a className={"text-white text-sm font-normal"}>
					<Image src={"/Formica AI.webp"} alt={"Formica AI"} width={180} height={20}
					       className={"max-w-full max-h-full h-5 -mt-0.5"}/>
				</a>
			</div>
		</GenericContainer>
	);
}
