import GenericButton from "@/components/generic/GenericButton";

export default function HeroContent({title, subtitle, subtitle2, description, buttonTitle, buttonHref}: HeroContentProps) {
	return (
			<div className={"flex flex-col justify-center items-center w-full min-h-fit px-4 md:px-9 xl:px-0"}>
				<div className={"flex flex-col justify-center items-center text-center w-full h-full gap-5 md:gap-6 xl:gap-7 whitespace-pre-wrap"}>
					{title && <p className={"text-sm md:text-base xl:text-lg font-medium text-formica md:whitespace-normal whitespace-pre-wrap"}>{title}</p>}
					{subtitle && <p className={"text-2xl md:text-3.5xl xl:text-3.5xl font-bold text-white whitespace-pre-wrap"}>{subtitle}</p>}
					{subtitle2 && <p className={"text-2xl md:text-3.5xl xl:text-3.5xl font-bold text-white whitespace-pre-wrap"}>{subtitle2}</p>}
					{description && <p className={"text-sm md:text-base xl:text-lg font-light text-white whitespace-normal md:whitespace-pre-wrap"}>{description}</p>}
					{buttonTitle && <GenericButton href={buttonHref} title={buttonTitle} aClassName={"w-72"} variant={"blue"}/>}
				</div>
			</div>
	)

}

interface HeroContentProps {
	title?: string,
	subtitle?: string,
	subtitle2?: string,
	description?: string,
	buttonTitle?: string,
	buttonHref?: string,
}
